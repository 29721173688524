.espacio{
    min-height: 50vh;
}

.banneresperar {
    background-image: url(../img/quedeatento.jpg);
    width: 100%;
    height: 368px;
    padding: 40px;
    display: grid;
    align-content: center;
    
}

.square03{
    width: 360px;
    font-weight: 600;
    font-size: larger;
}