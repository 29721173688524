.tamanio{
    font-size: 32px;
    font-weight:700;
}

.fondocolor{
   background-color: #343a40!important;
}
.orange{
    color: orangered;
}