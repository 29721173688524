.espaciomin{
    min-height: 300px;
}

.anchot{
    width: fit-content;
}

.premiar{
    background-image: url(../img/premios.jpg);
    width: 100%;
    height: 307px;
    background-position: center;
    background-repeat: no-repeat;
}
@media (max-width: 600px) {
   .premiar{
       background-image: url(../img/premios-vertical.jpg);
       width: 100%;
        height:508px;
        background-position: center;
        background-repeat: no-repeat;
   }
  }