.footerx{
    background-color: black;
    color: white;
}
.power{
    background-color: #212529;
    color: white;
    height: 38px;
    display: grid;
    text-align: center;
    align-content: center;
}

.acomodo{
    display: grid;
    align-items: end;
    align-content: center;
    text-align: end;
}

.text-gray{
    color:gray;
}

.widthlogo {
    width: 100%;
}