.espacio{
    min-height: 50vh;
}

.organigrama{
    width: -webkit-fill-available;
    height: 510px;
    background-image: url(../img/organigrama.png);
    background-repeat: no-repeat;
    background-position: center;
}

@media (max-width: 600px) {
    .organigrama{
        width: -webkit-fill-available;
        height: 683px;
        background-image: url(../img/organigrama-v.png);
        background-repeat: no-repeat;
        background-position: center;
    }
  }