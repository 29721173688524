.fondorojo{
    background-color: orangered;
    color: white;
    min-height: 152px;
    display: flex;
    font-size: xx-large;
    font-weight: 600;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

@media (max-width: 600px) {
    .fondorojo{
        background-color: orangered;
        color: white;
        min-height: 252px;
        display: flex;
        font-size: xx-large;
        font-weight: 600;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
    }
  }

  .estilo-uno {
    font-size: xx-large;  
  }

  @media (max-width: 600px) {
    .estilo-uno {
        font-size: x-large;  
      }
  }
.espacioderecho{
    margin-right: 28px;
}

@media (max-width: 600px) {
    .espacioderecho{
        margin-right: 0px;
    }
  }