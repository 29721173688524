body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.tamanio{
    font-size: 32px;
    font-weight:700;
}

.fondocolor{
   background-color: #343a40!important;
}
.orange{
    color: orangered;
}
.saliendo{
    cursor: pointer;
}

.espaciox{
    margin-left: 8px;
}
.imagen002{
    background-image: url(/static/media/0001.b27725c5.png);
    height: 230px;
    width: 100%;
    background-size: cover;
    background-position-x: center;
    background-position-y: 463px;
}

.bold{
    font-weight: 700;
}
.xxl{
    font-size: 60px;
}
.xl2{
    font-size: 42px;
}

.xl1{
    font-size: 34px;
}
.oranged{
    color: orangered;
}
.centradovertical {
    display: grid;
    align-content: center;
    justify-content: end;
}

.altura{
    height: 65vh;
}
.text-right{
    text-align: end;
}
.izquierda{
    text-align: left;
}
.justificar{
    text-align: justify;
}
.fondorojo{
    background-color: orangered;
    color: white;
    min-height: 152px;
    display: flex;
    font-size: xx-large;
    font-weight: 600;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

@media (max-width: 600px) {
    .fondorojo{
        background-color: orangered;
        color: white;
        min-height: 252px;
        display: flex;
        font-size: xx-large;
        font-weight: 600;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
    }
  }

  .estilo-uno {
    font-size: xx-large;  
  }

  @media (max-width: 600px) {
    .estilo-uno {
        font-size: x-large;  
      }
  }
.espacioderecho{
    margin-right: 28px;
}

@media (max-width: 600px) {
    .espacioderecho{
        margin-right: 0px;
    }
  }
.espacio{
    min-height: 50vh;
}
.tele{
    display: none;
}
@media (max-width: 990px) {
    .tele{
        display: inherit;
        margin-bottom: 20px;
    }
  }
.bigbox-contenedor {
    background-color: #ececec;
    width: 40%;
    height: 150px;
    border-radius: 10px;
    box-shadow: 10px 10px 20px 0px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 5px solid white;
    display: none;
    opacity: 0;
    z-index: 9999;
    /* color: orangered; */
}

@media only screen and (max-width: 600px) {
    .bigbox-contenedor{
        width: 80%;
    }
}

.bigbox-cerrar{
    float: right;
    width: 45px;
    height: 45px;

    cursor: pointer;
    color: orangered;

    text-align: center;
    padding-top: 10px;

    font-size: 20px;
    padding-top: 8px;

}

.bigbox-contenido{
    margin-top: 20px;
    margin-left: 20px;
    text-align: left;
}

.bigbox-contenido span{
    display: block;
}

.bigbox-titulo{
    font-size: 28px;
    color: orangered;
}

.bigbox-texto{
    font-size: 19px;
    color: orangered;
}

.bigbox-fondo{
    width: 100%;
    height: 100%;
    background-color: #0000003b;
    position: absolute;
    z-index: 9998;
    display: none;
}

.bigbox-entrada {
    -webkit-animation: bigbox-entrada 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: bigbox-entrada 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes bigbox-entrada {
    0% {
      -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
              transform: translateY(-600px) rotateX(-30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% 1400px;
              transform-origin: 50% 1400px;
      opacity: 1;
    }
}
@keyframes bigbox-entrada {
    0% {
      -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
              transform: translateY(-600px) rotateX(-30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% 1400px;
              transform-origin: 50% 1400px;
      opacity: 1;
    }
}

.bigbox-salida {
    -webkit-animation: bigbox-salida 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            animation: bigbox-salida 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes bigbox-salida {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
}

@keyframes bigbox-salida {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
}
  
.espacio{
    min-height: 50vh;
}

.banneresperar {
    background-image: url(/static/media/quedeatento.137c9821.jpg);
    width: 100%;
    height: 368px;
    padding: 40px;
    display: grid;
    align-content: center;
    
}

.square03{
    width: 360px;
    font-weight: 600;
    font-size: larger;
}
.espacio{
    min-height: 50vh;
}

.organigrama{
    width: -webkit-fill-available;
    height: 510px;
    background-image: url(/static/media/organigrama.ad3c9d73.png);
    background-repeat: no-repeat;
    background-position: center;
}

@media (max-width: 600px) {
    .organigrama{
        width: -webkit-fill-available;
        height: 683px;
        background-image: url(/static/media/organigrama-v.b5b24909.png);
        background-repeat: no-repeat;
        background-position: center;
    }
  }
.imagen001{
    background-image: url(/static/media/0001.b27725c5.png);
    height: 630px;
    width: 100%;
    background-size: cover;
    background-position-x: center;
}

.bold{
    font-weight: 700;
}
.xxl{
    font-size: 60px;
}
.xl2{
    font-size: 42px;
}

.xl1{
    font-size: 34px;
}
.oranged{
    color: orangered;
}
.centradovertical {
    display: grid;
    align-content: center;
    justify-content: end;
}

.altura{
    height: 65vh;
}
.text-right{
    text-align: end;
}

.touchable{
    cursor: pointer;
}

.text-left {
    text-align: start;
}
.bases{
    color: white;
}
.bases:hover{
    color:orangered;
    ;
}
.text-registro{
    color: black;
}
.verpremios{
    background-color: #343a40!important;
    height: 51px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    display: grid;
    align-content: center;
    font-size: 25px;
    font-weight: 700;
}

@media (max-width: 600px) {
    .verpremios{
        background-color: #343a40!important;
        height: 110px;
        color: white;
        text-align: center;
        text-transform: uppercase;
        display: grid;
        align-content: center;
        font-size: 25px;
        font-weight: 700;
    }
  }

.mmll {
    text-align: left;
    text-align: initial;
}
@media (max-width: 600px) {
    .mmll {
        text-align: center;
    }
  }

.mmrr{text-align: end;}

@media (max-width: 600px) {
    .mmrr {
        text-align: center;
    }
  }

  .nodeco{
    text-decoration: none!important;
    color: orangered!important;
  }
  .nodeco:hover{
    text-decoration: none!important;
    color: rgb(189, 189, 189)!important;
  }

  .btn-warning:hover {
    color: #000;
    background-color: #977202;
    border-color: #3a2e09;
}
.nodecor{
  text-decoration: none!important;
  color: rgb(207, 207, 207)!important;
}
.nodecor:hover{
  text-decoration: none!important;
  color: rgb(189, 189, 189)!important;
}
.text-center{
    text-align: center;
}

h3{
    font-weight: 700;
}

.centradoverticalvideo{
    display: grid;
    align-content: center;
    justify-items: center;

}

.anchort{
    width: 80%;
}

@media (max-width: 600px) {
    .anchort{
        width: 100%;
    }
  }
h4{

    font-weight: bold;

    font-size: 31px;

}



.padre {

   display: table;

  

}

.hijo {

   display: table-cell;

   vertical-align: middle;

}

 

 .header-section{

    background-image: url(/static/media/concurso-background.b27725c5.png);

    width: 100%;

    height: 68vh;

    background-position-x: center;

     background-size: cover;

 }



 @media (orientation: portrait) {

    .header-section{

        background-image: url(/static/media/concurso-background.b27725c5.png);

        width: 100%;

        height: 70vh;

        background-position-x: center;

        background-size: cover;

        background-position-x: 39%;

     }

  }



.btn-orange{

    background-color: orangered;

    border-color: orangered;

    color: wheat;

    font-size: 17px;

    font-weight: 700;

    text-transform: uppercase

}

.btn-orange:hover{

    background-color: rgb(143, 45, 9);

    border-color: orangered;

    color: wheat;

    font-size: 17px;

    font-weight: 700;

    text-transform: uppercase

}





.gradient-grey{

    background: rgb(246,246,246);

     background: radial-gradient(circle, rgba(246,246,246,1) 26%, rgba(245,245,245,1) 65%, rgba(230,230,230,1) 100%);

     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f6f6",endColorstr="#e6e6e6",GradientType=1)

}

.section{

    min-height: 350px;

    padding-top: 45px;

    padding-bottom: 45px;

}



.naipe{

    min-height: 278px;

    width: 100%;

    background-color: white;

    border-radius: 8px;

    box-shadow: -1px 1px 11px #000;

    color: black;

    font-size: 13px;

    text-align: center;

    margin-bottom: 20px;

}

.naipe-img{

    object-fit: cover;

    width: 100%;

    border-radius: 12px 12px 1px 1px;

    height: 125px;

    object-position: center;

}



.numero{

    font-size: 95px;

    font-weight: 900;

    color: #ffffff96;

    position: absolute;

    right: 25px;

    top: -37px;

}

.paddingcito{

       padding: 28px 27px 4px 24px;

}



.col-md-2{

    position: relative;

    

    padding-right: 5px!important;

    padding-left: 5px!important;

}



.participa{

    height: 80px;

    color: white;

    background-color: #ff581a;

    font-size: x-large;

    text-align: center;

}



.madera{

    background-image: url(/static/media/wood_texture3178.c127693b.jpg);

    background-size: cover;

    background-color: #a23d217d!important;

    background-blend-mode: color-burn;

}



.arrow{

position: absolute;

right: -14px;

bottom: 126px;

color: orangered;

font-size: 34px;

z-index: 120;

}



.arrow::before{

    width: 20px;

    height: 20px;

    background-color: white;

    border-radius: 18px;

    box-shadow: 0px 0px 8px #000;

}



.arrow-dos{

position: absolute;

right: -14px;

bottom: 126px;

color: orangered;

font-size: 34px;

z-index: 120;

}



.arrow-dos::before{

    width: 20px;

    height: 20px;

    background-color: white;

    border-radius: 18px;

    box-shadow: 0px 0px 8px #000;

}









@media screen and (max-width: 700px) {

    .arrow-dos {

    position: absolute;

    right: 78px;

    bottom: 6px;

    color: orangered;

    font-size: 34px;

    z-index: 120;

    -webkit-transform: rotate(153deg);

            transform: rotate(153deg);
}

    }


.fecha{

    font-size: 20px;

}



@media screen and (max-width: 700px) {

    .fecha {

    font-size: 18px;

}

    }





@media screen and (max-width: 700px) {

    .fotografos {

    display: none;

}

    }





   @media screen and (max-width: 700px) {

    .altodos {

    height: 100px!important;

}

    }



    .row {

    display: flex;

    flex-wrap: wrap;

    margin-right: 0px!important;

    margin-left: 0px!important;

}



.navbar-nav.navbar-center {

    position: absolute;

    left: 50%;

    -webkit-transform: translatex(-50%);

            transform: translatex(-50%);

}

.margenx{
    margin: 1px 1px 4px 1px;font-size: 13px;
    margin-top: 5px;
}
.espaciomin{
    min-height: 300px;
}

.anchot{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.premiar{
    background-image: url(/static/media/premios.8904e9fb.jpg);
    width: 100%;
    height: 307px;
    background-position: center;
    background-repeat: no-repeat;
}
@media (max-width: 600px) {
   .premiar{
       background-image: url(/static/media/premios-vertical.26d93410.jpg);
       width: 100%;
        height:508px;
        background-position: center;
        background-repeat: no-repeat;
   }
  }
.negro{
    background-color: black;
    min-height: 65vh;
    background-image: url(/static/media/fondo-registro.29a97e9d.jpg);
    background-position-y: center;
    background-position-x: center;
}

@media (max-width: 600px) {
    .negro{
        display: none;
        background-color: black;
        min-height: 65vh;
        background-image: url(/static/media/fondo-registro.29a97e9d.jpg);
        background-position-y: center;
        background-position-x: center;
    }
   }

.formregistro{
    
    height: 400px;
    display: grid;
    padding: 24px;
}

.vertical{
    display: grid;
    align-content: center;
}

.margen{
    margin-top: 8px;
}

.formcelu{
    display: block;
    width: 500px;
    display: grid;
    align-content: center;
}

@media (max-width: 600px) {
    .formcelu{
        margin-bottom: 179px;
        margin-top: 171px;
        padding: 1px;
    }
   }

.btonblack{
    width: 100%;
    background-color: black;
    text-decoration: none;
    color: white;
    padding: 6px;
    border-radius: 13px;
    font-size: 13px;
}

.btonblack:hover{
    width: 100%;
    background-color: orangered;
    text-decoration: none;
    color: white!important;
    padding: 6px;
    border-radius: 13px;
    font-size: 13px;
}
.margenleft{
    margin-left: 13px!important;
}
.form-control:focus {
    color: #ffffff;
    background-color: #000;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
}
.footerx{
    background-color: black;
    color: white;
}
.power{
    background-color: #212529;
    color: white;
    height: 38px;
    display: grid;
    text-align: center;
    align-content: center;
}

.acomodo{
    display: grid;
    align-items: end;
    align-content: center;
    text-align: end;
}

.text-gray{
    color:gray;
}

.widthlogo {
    width: 100%;
}
