.text-center{
    text-align: center;
}

h3{
    font-weight: 700;
}

.centradoverticalvideo{
    display: grid;
    align-content: center;
    justify-items: center;

}

.anchort{
    width: 80%;
}

@media (max-width: 600px) {
    .anchort{
        width: 100%;
    }
  }