.negro{
    background-color: black;
    min-height: 65vh;
    background-image: url(../img/fondo-registro.jpg);
    background-position-y: center;
    background-position-x: center;
}

@media (max-width: 600px) {
    .negro{
        display: none;
        background-color: black;
        min-height: 65vh;
        background-image: url(../img/fondo-registro.jpg);
        background-position-y: center;
        background-position-x: center;
    }
   }

.formregistro{
    
    height: 400px;
    display: grid;
    padding: 24px;
}

.vertical{
    display: grid;
    align-content: center;
}

.margen{
    margin-top: 8px;
}

.formcelu{
    display: block;
    width: 500px;
    display: grid;
    align-content: center;
}

@media (max-width: 600px) {
    .formcelu{
        margin-bottom: 179px;
        margin-top: 171px;
        padding: 1px;
    }
   }

.btonblack{
    width: 100%;
    background-color: black;
    text-decoration: none;
    color: white;
    padding: 6px;
    border-radius: 13px;
    font-size: 13px;
}

.btonblack:hover{
    width: 100%;
    background-color: orangered;
    text-decoration: none;
    color: white!important;
    padding: 6px;
    border-radius: 13px;
    font-size: 13px;
}
.margenleft{
    margin-left: 13px!important;
}
.form-control:focus {
    color: #ffffff;
    background-color: #000;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
}