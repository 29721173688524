h4{

    font-weight: bold;

    font-size: 31px;

}



.padre {

   display: table;

  

}

.hijo {

   display: table-cell;

   vertical-align: middle;

}

 

 .header-section{

    background-image: url(../img/concurso-background.png);

    width: 100%;

    height: 68vh;

    background-position-x: center;

     background-size: cover;

 }



 @media (orientation: portrait) {

    .header-section{

        background-image: url(../img/concurso-background.png);

        width: 100%;

        height: 70vh;

        background-position-x: center;

        background-size: cover;

        background-position-x: 39%;

     }

  }



.btn-orange{

    background-color: orangered;

    border-color: orangered;

    color: wheat;

    font-size: 17px;

    font-weight: 700;

    text-transform: uppercase

}

.btn-orange:hover{

    background-color: rgb(143, 45, 9);

    border-color: orangered;

    color: wheat;

    font-size: 17px;

    font-weight: 700;

    text-transform: uppercase

}





.gradient-grey{

    background: rgb(246,246,246);

     background: -moz-radial-gradient(circle, rgba(246,246,246,1) 26%, rgba(245,245,245,1) 65%, rgba(230,230,230,1) 100%);

     background: -webkit-radial-gradient(circle, rgba(246,246,246,1) 26%, rgba(245,245,245,1) 65%, rgba(230,230,230,1) 100%);

     background: radial-gradient(circle, rgba(246,246,246,1) 26%, rgba(245,245,245,1) 65%, rgba(230,230,230,1) 100%);

     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f6f6",endColorstr="#e6e6e6",GradientType=1)

}

.section{

    min-height: 350px;

    padding-top: 45px;

    padding-bottom: 45px;

}



.naipe{

    min-height: 278px;

    width: 100%;

    background-color: white;

    border-radius: 8px;

    box-shadow: -1px 1px 11px #000;

    color: black;

    font-size: 13px;

    text-align: center;

    margin-bottom: 20px;

}

.naipe-img{

    object-fit: cover;

    width: 100%;

    border-radius: 12px 12px 1px 1px;

    height: 125px;

    object-position: center;

}



.numero{

    font-size: 95px;

    font-weight: 900;

    color: #ffffff96;

    position: absolute;

    right: 25px;

    top: -37px;

}

.paddingcito{

       padding: 28px 27px 4px 24px;

}



.col-md-2{

    position: relative;

    

    padding-right: 5px!important;

    padding-left: 5px!important;

}



.participa{

    height: 80px;

    color: white;

    background-color: #ff581a;

    font-size: x-large;

    text-align: center;

}



.madera{

    background-image: url(../img/wood_texture3178.jpg);

    background-size: cover;

    background-color: #a23d217d!important;

    background-blend-mode: color-burn;

}



.arrow{

position: absolute;

right: -14px;

bottom: 126px;

color: orangered;

font-size: 34px;

z-index: 120;

}



.arrow::before{

    width: 20px;

    height: 20px;

    background-color: white;

    border-radius: 18px;

    box-shadow: 0px 0px 8px #000;

}



.arrow-dos{

position: absolute;

right: -14px;

bottom: 126px;

color: orangered;

font-size: 34px;

z-index: 120;

}



.arrow-dos::before{

    width: 20px;

    height: 20px;

    background-color: white;

    border-radius: 18px;

    box-shadow: 0px 0px 8px #000;

}









@media screen and (max-width: 700px) {

    .arrow-dos {

    position: absolute;

    right: 78px;

    bottom: 6px;

    color: orangered;

    font-size: 34px;

    z-index: 120;

    transform: rotate(153deg);
}

    }


.fecha{

    font-size: 20px;

}



@media screen and (max-width: 700px) {

    .fecha {

    font-size: 18px;

}

    }





@media screen and (max-width: 700px) {

    .fotografos {

    display: none;

}

    }





   @media screen and (max-width: 700px) {

    .altodos {

    height: 100px!important;

}

    }



    .row {

    display: -ms-flexbox;

    display: flex;

    -ms-flex-wrap: wrap;

    flex-wrap: wrap;

    margin-right: 0px!important;

    margin-left: 0px!important;

}



.navbar-nav.navbar-center {

    position: absolute;

    left: 50%;

    transform: translatex(-50%);

}

.margenx{
    margin: 1px 1px 4px 1px;font-size: 13px;
    margin-top: 5px;
}