.imagen001{
    background-image: url(../img/0001.png);
    height: 630px;
    width: 100%;
    background-size: cover;
    background-position-x: center;
}

.bold{
    font-weight: 700;
}
.xxl{
    font-size: 60px;
}
.xl2{
    font-size: 42px;
}

.xl1{
    font-size: 34px;
}
.oranged{
    color: orangered;
}
.centradovertical {
    display: grid;
    align-content: center;
    justify-content: end;
}

.altura{
    height: 65vh;
}
.text-right{
    text-align: end;
}

.touchable{
    cursor: pointer;
}

.text-left {
    text-align: start;
}
.bases{
    color: white;
}
.bases:hover{
    color:orangered;
    ;
}