.bigbox-contenedor {
    background-color: #ececec;
    width: 40%;
    height: 150px;
    border-radius: 10px;
    box-shadow: 10px 10px 20px 0px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 5px solid white;
    display: none;
    opacity: 0;
    z-index: 9999;
    /* color: orangered; */
}

@media only screen and (max-width: 600px) {
    .bigbox-contenedor{
        width: 80%;
    }
}

.bigbox-cerrar{
    float: right;
    width: 45px;
    height: 45px;

    cursor: pointer;
    color: orangered;

    text-align: center;
    padding-top: 10px;

    font-size: 20px;
    padding-top: 8px;

}

.bigbox-contenido{
    margin-top: 20px;
    margin-left: 20px;
    text-align: left;
}

.bigbox-contenido span{
    display: block;
}

.bigbox-titulo{
    font-size: 28px;
    color: orangered;
}

.bigbox-texto{
    font-size: 19px;
    color: orangered;
}

.bigbox-fondo{
    width: 100%;
    height: 100%;
    background-color: #0000003b;
    position: absolute;
    z-index: 9998;
    display: none;
}

.bigbox-entrada {
    animation: bigbox-entrada 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes bigbox-entrada {
    0% {
      transform: translateY(-600px) rotateX(-30deg) scale(0);
      transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      transform: translateY(0) rotateX(0) scale(1);
      transform-origin: 50% 1400px;
      opacity: 1;
    }
}

.bigbox-salida {
    animation: bigbox-salida 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes bigbox-salida {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 1;
    }
}
  