.imagen002{
    background-image: url(../img/0001.png);
    height: 230px;
    width: 100%;
    background-size: cover;
    background-position-x: center;
    background-position-y: 463px;
}

.bold{
    font-weight: 700;
}
.xxl{
    font-size: 60px;
}
.xl2{
    font-size: 42px;
}

.xl1{
    font-size: 34px;
}
.oranged{
    color: orangered;
}
.centradovertical {
    display: grid;
    align-content: center;
    justify-content: end;
}

.altura{
    height: 65vh;
}
.text-right{
    text-align: end;
}