.verpremios{
    background-color: #343a40!important;
    height: 51px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    display: grid;
    align-content: center;
    font-size: 25px;
    font-weight: 700;
}

@media (max-width: 600px) {
    .verpremios{
        background-color: #343a40!important;
        height: 110px;
        color: white;
        text-align: center;
        text-transform: uppercase;
        display: grid;
        align-content: center;
        font-size: 25px;
        font-weight: 700;
    }
  }

.mmll {
    text-align: initial;
}
@media (max-width: 600px) {
    .mmll {
        text-align: center;
    }
  }

.mmrr{text-align: end;}

@media (max-width: 600px) {
    .mmrr {
        text-align: center;
    }
  }

  .nodeco{
    text-decoration: none!important;
    color: orangered!important;
  }
  .nodeco:hover{
    text-decoration: none!important;
    color: rgb(189, 189, 189)!important;
  }

  .btn-warning:hover {
    color: #000;
    background-color: #977202;
    border-color: #3a2e09;
}
.nodecor{
  text-decoration: none!important;
  color: rgb(207, 207, 207)!important;
}
.nodecor:hover{
  text-decoration: none!important;
  color: rgb(189, 189, 189)!important;
}